// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-calendar-js": () => import("./../src/pages/calendar.js" /* webpackChunkName: "component---src-pages-calendar-js" */),
  "component---src-pages-careers-js": () => import("./../src/pages/careers.js" /* webpackChunkName: "component---src-pages-careers-js" */),
  "component---src-pages-child-inquiry-js": () => import("./../src/pages/child-inquiry.js" /* webpackChunkName: "component---src-pages-child-inquiry-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-our-curriculum-js": () => import("./../src/pages/our-curriculum.js" /* webpackChunkName: "component---src-pages-our-curriculum-js" */),
  "component---src-pages-parents-js": () => import("./../src/pages/parents.js" /* webpackChunkName: "component---src-pages-parents-js" */)
}

